<template>
  <b-card>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- form -->
      <b-form
        ref="refForm"
        class="mt-1"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetLocalForm"
      >
        <b-row>
          <!-- role -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              :label="$t('User Role')"
              label-for="user-role-user"
            >
              <v-select
                v-model="localData.role_user"
                :options="localData.role_options"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-role-user"
                :get-option-label="option => $t(option.label)"
              />
            </b-form-group>
          </b-col>

        </b-row>

        <b-row>
          <b-col cols="8">
            <b-button
              ref="submit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :disabled="busy"
              @click="onSubmit"
            >
              {{ $t('Save changes') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetLocalForm"
            >
              {{ $t('Reset') }}
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="text-right d-none d-md-block d-lg-block"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-primary"
              type="button"
              class="mt-2"
              @click.prevent="gotoUserList"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="12"
              />
              <span class="ml-25 align-middle"> {{ `${$t('Goto')} ${$t('User List')}` }}</span>
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="d-lg-none d-md-none d-sm-block"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-primary"
              type="button"
              class="mt-2"
              @click.prevent="gotoUserList"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="12"
              />
              <span class="ml-25 align-middle"> {{ `${$t('Goto')} ${$t('User List')}` }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              ref="dialogOk"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BRow, BCol, BCard, BCardText,
  BOverlay,
  BProgress,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import {
  ref,
  onUnmounted,
} from '@vue/composition-api'
import {
  // ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
// import { t } from '@/@core/libs/i18n/utils'
import { $themeConfig } from '@themeConfig'
import userStoreModule from '../userStoreModule'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BOverlay,
    BProgress,
    vSelect,

    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    bookingData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localData: JSON.parse(JSON.stringify(this.bookingData)),
      userData: JSON.parse(localStorage.getItem(`${$themeConfig.app.commonSession}-user-data`)),
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      isAction: null,
    }
  },
  mounted() {
    // this.$refs.submit.focus()
  },
  methods: {
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialogOk.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
          this.isAction = 'form'
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      if (this.isAction === 'form') {
        store.dispatch('user-store/editUser', { id: this.$router.currentRoute.params.id, data: { booking: this.localData } })
          .then(response => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false
            this.$emit('update-user-edit', response.data)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              let errorMsg = this.$i18n.t(response?.data.error)
              if (errorMsg === '') {
                errorMsg = this.$i18n.t(response?.data.message)
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      }
      this.$refs.submit.focus()
    },
    resetLocalForm() {
      this.localData = JSON.parse(JSON.stringify(this.bookingData))
      this.$refs.refFormObserver.reset()
      this.$refs.submit.focus()
    },
    gotoUserList() {
      this.$router.push({ name: 'user-list' })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { previewEl.value.src = base64 })

    const STORE_MODULE_NAME = 'user-store'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const roleOptions = [
      // { label: t('Admin'), value: 'admin' },
      // { label: t('Staff'), value: 'staff' },
      // { label: t('Employee'), value: 'employee' },
    ]

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,

      roleOptions,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
