<template>
  <b-card>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- form -->
      <b-form
        ref="refForm"
        class="mt-1"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetLocalForm"
      >
        <b-row>
          <!-- PERMISSION TABLE -->
          <b-col
            cols="12"
          >
            <b-card
              no-body
              class="border"
            >
              <b-card-header class="p-1">
                <b-card-title class="font-medium-2">
                  <feather-icon
                    icon="LockIcon"
                    size="18"
                  />
                  <span class="align-middle ml-50">{{ $t('Permission') }}</span>
                </b-card-title>
              </b-card-header>
              <b-table
                striped
                responsive
                class="mb-0"
                :items="localData.permissions"
                :fields="permissionsTableColumns"
              >
                <!-- eslint-disable-next-line vue/no-unused-vars -->
                <template #thead-top="data">
                  <b-tr>
                    <b-th>
                      <div class="d-flex">
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="selectPermission"
                        >
                          <feather-icon
                            icon="DownloadIcon"
                            size="16"
                          />
                          {{ `${$t('Select')} ${$t('Permission')}` }}
                        </b-button>
                        <span class="form-info-box ml-1">{{ permissionSelectData.name }}</span>
                      </div>
                    </b-th>
                    <b-th>
                      <b-form-checkbox
                        v-model="readAll"
                        switch
                      />
                    </b-th>
                    <b-th>
                      <b-form-checkbox
                        v-model="writeAll"
                        switch
                      />
                    </b-th>
                    <b-th>
                      <b-form-checkbox
                        v-model="createAll"
                        switch
                      />
                    </b-th>
                    <b-th>
                      <b-form-checkbox
                        v-model="deleteAll"
                        switch
                      />
                    </b-th>
                  </b-tr>
                </template>
                <template #cell(module)="data">
                  <div class="d-flex">
                    <b-form-checkbox
                      switch
                      @change="onModuleAll($event, data.item)"
                    />
                    {{ $t(data.value) }}
                  </div>
                </template>

                <template #cell(read)="data">
                  <b-form-checkbox
                    v-model="data.item.read"
                    switch
                  />
                </template>

                <template #cell(write)="data">
                  <b-form-checkbox
                    v-model="data.item.write"
                    switch
                  />
                </template>

                <template #cell(create)="data">
                  <b-form-checkbox
                    v-model="data.item.create"
                    switch
                  />
                </template>

                <template #cell(delete)="data">
                  <b-form-checkbox
                    v-model="data.item.delete"
                    switch
                  />
                </template>
              </b-table>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="8">
            <b-button
              ref="submit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :disabled="busy"
              @click="onSubmit"
            >
              {{ $t('Save changes') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetLocalForm"
            >
              {{ $t('Reset') }}
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="text-right d-none d-md-block d-lg-block"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-primary"
              type="button"
              class="mt-2"
              @click.prevent="gotoUserList"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="12"
              />
              <span class="ml-25 align-middle"> {{ `${$t('Goto')} ${$t('User List')}` }}</span>
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="d-lg-none d-md-none d-sm-block"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-primary"
              type="button"
              class="mt-2"
              @click.prevent="gotoUserList"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="12"
              />
              <span class="ml-25 align-middle"> {{ `${$t('Goto')} ${$t('User List')}` }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              ref="dialogOk"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>

    <!-- Permission Select -->
    <permission-select
      v-model="showPermissionSelectModal"
      :title="`${$t('Select')} ${$t('Permission')}`"
      :permission-data="permissionSelectData"
      @update-permission-select="updatePermissionSelect"
      @discard-permission-select="discardPermissionSelect"
    />
  </b-card>
</template>

<script>
import {
  BButton, BForm, BRow, BCol, BCard, BCardText,
  BCardHeader,
  BCardTitle,
  BOverlay,
  BProgress,
  BTable,
  BFormCheckbox,
  BTr,
  BTh,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  onUnmounted,
} from '@vue/composition-api'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import { t } from '@/@core/libs/i18n/utils'
import {
  ValidationObserver,
} from 'vee-validate'
import { $themeConfig } from '@themeConfig'
import PermissionSelect from '@/views/center/permission/PermissionSelect.vue'
import userStoreModule from '../userStoreModule'

export default {
  components: {
    BButton,
    BForm,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardHeader,
    BCardTitle,
    BOverlay,
    BProgress,
    BTable,
    BFormCheckbox,
    BTr,
    BTh,
    ValidationObserver,
    PermissionSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    permissionData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localData: JSON.parse(JSON.stringify(this.permissionData)),
      userData: JSON.parse(localStorage.getItem(`${$themeConfig.app.commonSession}-user-data`)),
      imageFile: null,
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      isAction: null,
      readAll: false,
      writeAll: false,
      createAll: false,
      deleteAll: false,
      showPermissionSelectModal: false,
      permissionSelectData: {},
    }
  },
  watch: {
    readAll(value) {
      this.localData.permissions.forEach(item => {
        item.read = value
      })
    },
    writeAll(value) {
      this.localData.permissions.forEach(item => {
        item.write = value
      })
    },
    createAll(value) {
      this.localData.permissions.forEach(item => {
        item.create = value
      })
    },
    deleteAll(value) {
      this.localData.permissions.forEach(item => {
        item.delete = value
      })
    },
  },
  mounted() {
    // this.$refs.submit.focus()
  },
  methods: {
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialogOk.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
          this.isAction = 'form'
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      if (this.isAction === 'form') {
        store.dispatch('user-store/editUser', { id: this.$router.currentRoute.params.id, data: this.localData })
          .then(response => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false
            this.$emit('update-user-edit', response.data)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              let errorMsg = this.$i18n.t(response?.data.error)
              if (errorMsg === '') {
                errorMsg = this.$i18n.t(response?.data.message)
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.isAction === 'file') {
        const formData = new FormData()
        formData.append('file', this.imageFile)

        store.dispatch('user-store/uploadImageUser', { id: this.$router.currentRoute.params.id, data: formData })
          .then(response => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false
            this.imageFile = null
            this.$emit('update-user-edit', response.data)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              let errorMsg = this.$i18n.t(response?.data.error)
              if (errorMsg === '') {
                errorMsg = this.$i18n.t(response?.data.message)
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
            // this.imageFile = null
          })
      }
      this.$refs.submit.focus()
    },
    resetLocalForm() {
      this.localData = JSON.parse(JSON.stringify(this.permissionData))
      this.$refs.refFormObserver.reset()
      this.$refs.submit.focus()
    },
    resetImage() {
      this.$refs.previewEl.src = this.localData.avatar
      this.imageFile = null
    },
    saveChangeImage() {
      if (this.imageFile !== null) {
        this.processing = false
        this.busy = true
        this.isAction = 'file'
      } else {
        const msg = this.$i18n.t('Please select image file')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: msg,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
        this.busy = false
        this.processing = false
      }
    },
    gotoUserList() {
      this.$router.push({ name: 'user-list' })
    },
    onModuleAll(event, item) {
      if (item.read !== undefined) {
        item.read = event
      }
      if (item.write !== undefined) {
        item.write = event
      }
      if (item.create !== undefined) {
        item.create = event
      }
      if (item.delete !== undefined) {
        item.delete = event
      }
    },
    selectPermission() {
      if (this.localData.permission_id === '') {
        this.permissionSelectData = {
          id: '',
          name: '',
        }
      } else {
        this.permissionSelectData = {
          id: '',
          name: '',
        }
        this.permissionSelectData.id = this.localData.permission_id
        this.permissionSelectData.name = this.localData.permission_name
      }
      this.showPermissionSelectModal = true
    },
    updatePermissionSelect(data) {
      this.$nextTick(() => {
        this.localData.permission_id = data.select.id
        this.localData.permission_name = data.select.name

        this.permissionSelectData.id = this.localData.permission_id
        this.permissionSelectData.name = this.localData.permission_name

        const permissionModule = data.select.permission_module
        permissionModule.forEach(item => {
          const search = this.localData.permissions.find(e => e.module === item.module)
          if (item.read !== undefined) {
            search.read = item.read
          }
          if (item.write !== undefined) {
            search.write = item.write
          }
          if (item.create !== undefined) {
            search.create = item.create
          }
          if (item.delete !== undefined) {
            search.delete = item.delete
          }
        })
      })
    },
    discardPermissionSelect() {
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'user-store'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const permissionsTableColumns = [
      { key: 'module', label: t('module') },
      { key: 'read', label: t('read') },
      { key: 'write', label: t('write') },
      { key: 'create', label: t('create') },
      { key: 'delete', label: t('delete') },
    ]

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    return {
      permissionsTableColumns,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
</style>
