<template>
  <b-tabs
    pills
    nav-wrapper-class="col-12"
    content-class="col-12 col-md-12 mt-1 mt-md-0"
  >
    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('General') }}</span>
      </template>

      <user-edit-general
        v-if="localUser.general"
        :general-data="localUser.general"
        @update-user-edit="updateUserEdit"
      />
    </b-tab>

    <!-- permission tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="KeyIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Permission') }}</span>
      </template>

      <user-edit-permission
        v-if="localUser.permission"
        :permission-data="localUser.permission"
        @update-user-edit="updateUserEdit"
      />
    </b-tab>

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Change Password') }}</span>
      </template>

      <user-edit-password />
    </b-tab>

    <!-- info -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Information') }}</span>
      </template>

      <user-edit-information
        v-if="localUser.info"
        :information-data="localUser.info"
        @update-user-edit="updateUserEdit"
      />
    </b-tab>

    <!-- social links -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LinkIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Social') }}</span>
      </template>

      <user-edit-social
        v-if="localUser.social"
        :social-data="localUser.social"
        @update-user-edit="updateUserEdit"
      />
    </b-tab>

    <!-- notification -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Notifications') }}</span>
      </template>

      <user-edit-notification
        v-if="localUser.notification"
        :notification-data="localUser.notification"
        @update-user-edit="updateUserEdit"
      />
    </b-tab>

    <!-- booking tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="CalendarIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Smart Booking') }}</span>
      </template>

      <user-edit-booking
        v-if="localUser.booking"
        :booking-data="localUser.booking"
        @update-user-edit="updateUserEdit"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { $themeConfig } from '@themeConfig'
import UserEditGeneral from './UserEditGeneral.vue'
import UserEditPermission from './UserEditPermission.vue'
import UserEditPassword from './UserEditPassword.vue'
import UserEditInformation from './UserEditInformation.vue'
import UserEditSocial from './UserEditSocial.vue'
import UserEditNotification from './UserEditNotification.vue'
import UserEditBooking from './UserEditBooking.vue'
import userStoreModule from '../userStoreModule'

export default {
  components: {
    BTabs,
    BTab,
    UserEditGeneral,
    UserEditPermission,
    UserEditPassword,
    UserEditInformation,
    UserEditSocial,
    UserEditNotification,
    UserEditBooking,
  },
  data() {
    return {
      localUser: {},
      appCode: $themeConfig.app.appCode,
    }
  },
  mounted() {
    this.getUser()
  },
  methods: {
    getUser() {
      store
        .dispatch('user-store/getUser',
          {
            id: router.currentRoute.params.id,
            appCode: this.appCode,
          })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.localUser = response.data
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
        })
    },
    updateUserEdit(data) {
      this.localUser = data
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'user-store'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, userStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    return {}
  },
}
</script>
